import { LitElement, css, html } from "lit";
import { danger, neutral } from "../../../legl-ui/lds-colours";
import "../../../legl-ui/lds-input-v2";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";

class LdsDOBFieldset extends LitElement {
  static get styles() {
    return css`
            :host {
                color: ${neutral["800"]};
            }
            ::slotted([slot="label"]) {
                ${typographyPresets.bodyBold};
                display: inline-block;
                margin-bottom: ${spacing.xxs};
                padding: 0;
            }
            fieldset {
                border: 0;
                padding: 0;
                margin: 0;
            }
            .dob-input {
                display: grid;
                grid-template-columns: auto auto auto;
                column-gap: ${spacing.xs};
            }
            .error-message {
                ${typographyPresets.small};
                display: flex;
                color: ${danger["500"]};
                margin: 0 0 0 ${spacing.xxs};
            }
        `;
  }

  static get properties() {
    return {
      optional: { attribute: false },
      errorMessage: { type: String },
    };
  }

  willUpdate(changedProperties) {
    super.willUpdate(changedProperties);
    if (changedProperties.has("errorMessage")) {
      if (this.errorMessage) {
        const slots = this.shadowRoot.querySelectorAll("slot");
        slots.forEach((slot) => {
          const assignedElements = slot.assignedElements();
          if (assignedElements.length > 0) {
            assignedElements[0].isInvalid = true;
          }
        });
      } else {
        const slots = this.shadowRoot.querySelectorAll("slot");
        slots.forEach((slot) => {
          const assignedElements = slot.assignedElements();
          if (assignedElements.length > 0) {
            assignedElements[0].isInvalid = false;
          }
        });
      }
    }
  }

  render() {
    return html`
            <fieldset>
                <slot name="label"></slot>
                <p class="error-message">${this.errorMessage}</p>
                <div class="dob-input">
                    <slot name="day-input"></slot>
                    <slot name="month-input"></slot>
                    <slot name="year-input"></slot>
                </div>
            </fieldset>
        `;
  }
}

if (!customElements.get("lds-date-of-birth-fieldset")) {
  customElements.define("lds-date-of-birth-fieldset", LdsDOBFieldset);
}

export class LdsDOBInput extends LitElement {
  createRenderRoot() {
    return this;
  }

  static get properties() {
    return {
      optional: { attribute: false },
      errorMessage: {
        type: String,
      },
      dayValue: { type: String, state: true },
      monthValue: { type: String, state: true },
      yearValue: { type: String, state: true },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("submit", this.triggerValidation);
  }

  disconnectedCallback() {
    this.removeEventListener("submit", this.triggerValidation);
  }

  triggerValidation(event) {
    const validationMessage = this.validate(event);
    if (validationMessage) {
      this.querySelector("lds-date-of-birth-fieldset").errorMessage =
        validationMessage;
    } else {
      this.querySelector("lds-date-of-birth-fieldset").errorMessage = "";
    }
  }

  validate(event) {
    const dayInput = this.querySelector("[name=day-input]");
    const monthInput = this.querySelector("[name=month-input]");
    const yearInput = this.querySelector("[name=year-input]");
    if (
      event?.type !== "submit" &&
      (!dayInput.touched || !monthInput.touched || !yearInput.touched)
    ) {
      return;
    }
    if (
      dayInput.value.length === 0 ||
      monthInput.value.length === 0 ||
      yearInput.value.length === 0
    ) {
      return "Please enter a date of birth";
    }
    if (
      dayInput.value.length !== 2 ||
      monthInput.value.length !== 2 ||
      yearInput.value.length !== 4
    ) {
      return "Please enter a valid date of birth";
    }
    const day = Number.parseInt(dayInput.value, 10);
    const month = Number.parseInt(monthInput.value, 10);
    const year = Number.parseInt(yearInput.value, 10);
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return "Please enter a valid date of birth";
    }
    if (day < 1 || day > 31) {
      return "Please enter a valid day";
    }
    if (month < 1 || month > 12) {
      return "Please enter a valid month";
    }
    if (year < 1900 || year > new Date().getFullYear()) {
      return "Please enter a valid year";
    }
    return "";
  }

  render() {
    return html`<lds-date-of-birth-fieldset
            .optional=${this.optional}
            .errorMessage=${this.errorMessage}
        >
            <legend slot="label">Date of birth${
              this.optional ? " (optional)" : ""
            }</legend>
            <lds-input-v2 u-sr-only slot="day-input"
                ><label slot="label" for="dob-day">Day</label>
                <input
                    slot="input"
                    id="dob-day"
                    placeholder="DD"
                    inputmode="numeric"
                    name="day-input"
                    value=${this.dayValue}
                    @change=${(e) => {
                      e.target.touched = true;
                      this.triggerValidation();
                    }}
                    size="2"
            /></lds-input-v2>
            <lds-input-v2 u-sr-only slot="month-input"
                ><label slot="label" for="dob-month">Month</label>
                <input
                    slot="input"
                    id="dob-month"
                    placeholder="MM"
                    inputmode="numeric"
                    name="month-input"
                    value=${this.monthValue}
                    @change=${(e) => {
                      e.target.touched = true;
                      this.triggerValidation();
                    }}
                    size="2"
            /></lds-input-v2>
            <lds-input-v2 u-sr-only slot="year-input"
                ><label slot="label" for="dob-year">Year</label>
                <input
                    slot="input"
                    id="dob-year"
                    placeholder="YYYY"
                    inputmode="numeric"
                    name="year-input"
                    value=${this.yearValue}
                    @change=${(e) => {
                      e.target.touched = true;
                      this.triggerValidation();
                    }}
                    size="4"
            /></lds-input-v2>
        </lds-date-of-birth-fieldset>`;
  }
}

if (!customElements.get("lds-date-of-birth-input")) {
  customElements.define("lds-date-of-birth-input", LdsDOBInput);
}
