import { createAction } from "@reduxjs/toolkit";

export const setSelectableRows = createAction(
  "engage-table/set-selectable-rows",
);
export const selectRow = createAction("engage-table/select-row");
export const unselectRow = createAction("engage-table/unselect-row");
export const selectCurrentPageRows = createAction(
  "engage-table/select-all-rows",
);
export const unselectAllRows = createAction("engage-table/unselect-all-rows");
export const unselectCurrentPageRows = createAction(
  "engage-table/unselect-all-selectable-rows",
);
export const filterSelectedRows = createAction(
  "engage-table/filter-selected-rows",
);

export default {
  setSelectableRows,
  selectRow,
  unselectRow,
  selectCurrentPageRows,
  unselectAllRows,
  unselectCurrentPageRows,
  filterSelectedRows,
};
