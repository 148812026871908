import { LitElement, css, html, nothing } from "lit";
import LeglFormValidationMixin from "../../../../../../../static-src/js/mixins/LeglFormValidationMixin.js";
import "../../../../../../apps/lawyers-contacts/contact-selector.js";
import "../../../../../../legl-ui/alert";
import "../../../../../../legl-ui/expandable-panel";
import "../../../../../../legl-ui/input/legl-input-file-upload.js";
import { neutral, primary } from "../../../../../../legl-ui/lds-colours";
import "../../../../../../legl-ui/lds-icon";
import "../../../../../../legl-ui/lds-input";
import { LeglMaxLength } from "../../../../../../legl-ui/lds-input";
import "../../../../../../legl-ui/lds-input-amount";
import "../../../../../../legl-ui/lds-select";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import "../../../../../../legl-ui/lds-tag";
import "../../../../../../legl-ui/lds-textarea";
import "../../../../../../legl-ui/lds-tooltip";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import "../../../../../../legl-ui/number";
import "../../../../../../legl-ui/steps";
import { toastService } from "../../../../../../legl-ui/toast";
import { formatDate } from "../../../../../../legl-ui/utils";
import { del, get, post } from "../../../../../../legl-ui/utils/fetch.js";
import "../../components/payment-type-selector/payment-type-selector.js";
import companyPaymentSettings from "../../config/company-payment-settings.js";
import "../request-success-message/request-success-message.js";

export class LeglPayRequestCreate extends LeglFormValidationMixin(LitElement) {
  static get styles() {
    return css`
            .lds-input,
            lds-alert {
                margin-bottom: ${spacing.s};
            }

            lds-alert {
                margin-top: ${spacing.xs};
            }

            .title {
                margin-top: 0;
                font-weight: 600;
            }

            .pay-request {
                background: var(--white);
                padding: 25px 35px 35px 45px;
                border-radius: 5px;
            }
            .pay-request__form {
                display: inline-block;
                gap: 10px;
                width: calc(100% - 10px);
                height: 100%;
                text-align: justify;
            }

            .pay-request__amount,
            .pay-request__account,
            .pay-request__doc-upload,
            .pay-request__comment {
                width: 100%;
            }

            .pay-request__label {
                ${typographyPresets.bodyBold};
                display: flex;
                margin-bottom: 6px;
                color: ${neutral["800"]};
            }

            .pay-request__label lds-icon {
                margin-left: ${spacing.xs};
            }

            .pay-request__doc-upload {
                margin-bottom: ${spacing.s};
            }

            .pay-request__doc-upload legl-input-file-upload {
                margin: 0;
            }

            .pay-request_success_title {
                grid-area: success-title;
                justify-self: center;
                color: ${neutral["800"]};
                font-size: 20px;
                font-weight: 700;
                margin: 0;
            }

            .pay-request__submit-button {
                grid-area: button;
                font-style: italic;
            }

            .pay-request__description {
                grid-area: description;
                justify-self: center;
                text-align: center;
                padding: 0 65px;
            }
            .pay-request__success-button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 260px;
            }
            .pay-request__link {
                font-size: 10px;
                text-align: center;
                color: var(--legl-grey-dark);
                text-decoration: none;
                text-align: center;
                margin-top: 5px;
            }
            .pay-request__close-button {
                grid-area: close-button;
                width: 100%;
                color: var(--legl-grey-dark);
                border-top: 1px solid var(--legl-grey-borders);
                border-radius: 0;
            }
            .details-card {
                grid-area: details-card;
                color: var(--legl-grey-dark);
                font-size: 12px;
                padding: 50px 30px;
                margin: 60px auto;
                max-width: 750px;
            }

            .details-card__content {
                display: flex;
                position: relative;
                justify-content: space-between;
                width: 100%;
            }

            .details-card__container {
                margin-right: 20px;
            }

            .details-card__row {
                display: flex;
                flex-wrap: wrap;
            }

            .details-card__item {
                min-width: 111px;
                margin-right: 20px;
            }

            .details-card__item-contact {
                min-width: 242px;
                margin-right: 20px;
            }
            .details-card__value {
                margin-top: 5px;
            }
            .details-card__value-contact {
                padding-top: 5px;
            }

            .details-card__total {
                text-align: end;
            }
            .details-card__total-value {
                font-size: 17px;
                font-weight: 700;
                margin: 0;
            }

            .details-card__invoice-link {
                color: ${primary["500"]};
                display: flex;
                align-items: center;
                gap: ${spacing.xs};
                text-decoration: none;
            }

            .details-card__invoice-link span {
                text-decoration: underline;
            }

            legl-checkbox {
                margin-bottom: 20px;
            }
            .payCreate__successPanel.--reminders {
                display: grid;
                grid-template:
                    "success-title success-title"
                    "remindersWrapper remindersWrapper"
                    "details-card details-card"
                    "close-button close-button"/ 50% 50%;
                justify-content: center;
                gap: 20px;
                margin-top: 80px;
            }
            .payCreate__successPanel.--wrapper {
                grid-area: remindersWrapper / remindersWrapper /
                    remindersWrapper / remindersWrapper;
                max-width: 750px;
                justify-self: center;
            }
            .payment-provider {
                display: flex;
                align-items: center;
            }
            .payment-providers-wrapper span.payment-provider {
                color: ${neutral["700"]};
                font-weight: normal;
            }
            .payment-provider lds-icon {
                margin-right: ${spacing.xs};
            }
            .payment-provider:first-child {
                margin-right: ${spacing.s};
            }
            .payment-providers-wrapper {
                display: flex;
                align-items: center;
                margin: ${spacing.xs} 0;
            }
            .payment-providers-wrapper span {
                ${typographyPresets.smallBold};
                font-weight: 600;
                color: ${neutral["700"]};
            }
            .payment-providers-wrapper lds-tooltip {
                display: flex;
            }
            .payment-providers-wrapper lds-tooltip lds-icon {
                color: ${neutral["700"]};
                padding: 0 ${spacing.xxs};
            }
            .payment-providers-wrapper .badges-wrapper {
                display: flex;
                gap: ${spacing.s};
                margin-left: ${spacing.s};
            }
        `;
  }

  constructor() {
    super();
    this.bankAccounts = [];
    this.payRequest = null;
    this.companyPaymentSettings = {};
    this.selectedContact = null;
    this.isSubmitting = false;
    this.currentAmount = null;
    this.companyName = null;
    this.documentIsUploading = null;
    this.paymentType = "single";
  }

  static get properties() {
    return {
      bankAccounts: { state: true },
      payRequest: { state: true },
      companyPaymentSettings: { state: true },
      selectedContact: { state: true },
      isSubmitting: { state: true },
      minimumPayment: { state: true },
      stripePaymentLimit: { state: true },
      bankedPaymentLimit: { state: true },
      currentAmount: { state: true },
      companyName: { state: true },
      documentIsUploading: { state: true },
      selectedBankAccount: { state: true },
      reminderStartAfterDays: { state: true },
      ccOnRequestEmail: { state: true },
      params: {
        type: URLSearchParams,
      },
    };
  }

  // overrides getter from LeglFormValidationMixin to work with new inputs
  get inputSelector() {
    return ".lds-input";
  }

  get form() {
    const body = {
      ...Object.fromEntries(
        new FormData(this.shadowRoot.querySelector("form")),
      ),
    };

    body.contact_uid = this.selectedContact.uid;
    body.first_name = this.selectedContact.first_name;
    body.middle_name = this.selectedContact.middle_name || "";
    body.last_name = this.selectedContact.last_name;
    body.client_reference = this.selectedContact.client_reference;
    body.email = this.selectedContact.email || null;
    body.payment_document_token = this.documentToken || null;
    body.amount = this.currentAmount;
    body.total_amount = body.total_amount?.replaceAll(",", "");
    this.totalAmount = body.total_amount;
    delete body.total_amount;
    delete body.payment_type;

    return JSON.stringify(body);
  }

  async requestAPI(path, body) {
    const res = await post(path, {
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    const JSONResponse = await res.json();
    if (!res.ok) {
      let errorMessage = "Error creating payment request";
      if (JSONResponse.detail) {
        errorMessage = JSONResponse.detail;
      } else if (Object.keys(JSONResponse).length) {
        errorMessage = Object.entries(JSONResponse)
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ");
      }
      throw new Error(errorMessage);
    }
    return JSONResponse;
  }

  async submitForm(e) {
    e.preventDefault();
    this.validate();
    if (this.isValid && !this.isSubmitting) {
      this.isSubmitting = true;
      try {
        this.payRequest = await this.requestAPI("/api/payments/", this.form);
        if (this.totalAmount) {
          this.scheduleResponse = await this.requestAPI(
            "/api/payment_schedules/",
            JSON.stringify({
              total_amount: this.totalAmount,
              initial_payment: this.payRequest.url,
            }),
          );
        }

        window.dispatchEvent(new CustomEvent("reload-payments-table"));
      } catch (error) {
        toastService.showError(error.message);
      } finally {
        this.isSubmitting = false;
      }
    }
    document
      .querySelector("legl-drawer")
      .shadowRoot.querySelector(".drawer__content").scrollTop = 0;
  }

  async fetchBankAccounts() {
    try {
      const res = await get(`/api/bank_accounts/`);
      if (!res.ok) {
        throw new Error(res.error);
      }
      this.bankAccounts = await res.json();
      this.selectedBankAccount = this.bankAccounts[0].url;
    } catch {
      toastService.showError("Error retrieving bank accounts");
    }
  }

  async fetchCompanyPaymentSettings() {
    try {
      await companyPaymentSettings.fetch();
      this.companyPaymentSettings = companyPaymentSettings.settings;
      this.minimumPayment = companyPaymentSettings.minimumPayment;
      this.stripePaymentLimit = companyPaymentSettings.stripePaymentLimit;
      this.bankedPaymentLimit = companyPaymentSettings.bankedPaymentLimit;
      this.companyName = companyPaymentSettings.companyName;
      this.reminderStartAfterDays =
        companyPaymentSettings.reminderStartAfterDays;
      this.currencyCode = this.companyPaymentSettings.company.currency;
      this.locale = this.companyPaymentSettings.company.locale;
      this.bccOnRequestEmail = companyPaymentSettings.bccOnRequestEmail;
      this.ccOnRequestEmail = companyPaymentSettings.ccOnRequestEmail;
      this.reminderStartAfterDaysOptions =
        companyPaymentSettings.reminderStartAfterDaysOptions;
      if (!Object.keys(this.companyPaymentSettings).length) {
        throw new Error(res.error);
      }
    } catch {
      toastService.showError("Error retrieving payment settings");
    }
  }

  async connectedCallback() {
    super.connectedCallback();
    this.fetchCompanyPaymentSettings();
    this.fetchBankAccounts();
  }

  newRequest() {
    this.reset();
    this.selectedContact = null;
    this.payRequest = null;
  }

  get requested_by() {
    return this?.payRequest?.requested_by?.name || "-";
  }

  get drawer() {
    return document.querySelector("legl-drawer");
  }

  get hasExceededMaxAmount() {
    return this.stripePaymentLimit
      ? this.currentAmount > this.stripePaymentLimit
      : false;
  }

  get bankAccountPaymentProviders() {
    if (this.selectedBankAccount) {
      return this.bankAccounts.find(
        (bankAccount) => bankAccount.url === this.selectedBankAccount,
      ).supported_providers;
    }
  }

  get payByCardAvailable() {
    return (
      this.currentAmount >= this.minimumPayment &&
      this.currentAmount <= this.stripePaymentLimit
    );
  }

  get payByBankAvailable() {
    return (
      this.currentAmount >= this.minimumPayment &&
      this.currentAmount <= this.bankedPaymentLimit &&
      this.paymentType !== "plan"
    );
  }

  setCurrentAmount = (value) => {
    this.currentAmount = value;
  };

  setPaymentType = (value) => {
    this.paymentType = value;
  };

  get paymentInformationFields() {
    return html` <payment-type-selector
                .setCurrentAmount=${this.setCurrentAmount}
                .setPaymentType=${this.setPaymentType}
                .scheduledPaymentsEnabled="${
                  this.companyPaymentSettings?.enable_scheduled_payments
                }"
                .minimumPayment=${this.minimumPayment}
                .currency=${this.currencyCode}
                .locale=${this.locale}
                .params=${this.params}
            >
            </payment-type-selector>
            ${
              (!this.bankAccountPaymentProviders?.includes("banked") ||
                this.paymentType === "plan") &&
              this.hasExceededMaxAmount
                ? html`<lds-alert .type=${"warning"} .title=${"Heads up!"}>
                      <p slot="content">
                          This amount exceeds the
                          ${html`<legl-currency
                              .value=${this.stripePaymentLimit}
                              .currency=${this.currencyCode}
                              .locale=${this.locale}
                          ></legl-currency>`}
                          maximum card payment limit configured by
                          ${this.companyName}
                      </p>
                  </lds-alert>`
                : nothing
            }
            <lds-select
                data-cy-create-pay-request-account
                label="To account"
                name="destination_bank_account_url"
                class="pay-request__account"
                @model-value-changed=${(e) =>
                  (this.selectedBankAccount = e.target.modelValue)}
            >
                <select slot="input">
                    ${this.bankAccounts.map((account) => {
                      return html` <option value=${account.url}>
                            ${account.name}
                        </option>`;
                    })}
                </select>
            </lds-select>

            <div class="payment-providers-wrapper">
                <span>Payment methods available</span>
                <lds-tooltip
                    .text=${"Available payment methods at the checkout depend on the account selected and on the payment limits set by your firm."}
                    placement="top-start"
                >
                    <lds-icon
                        class="info-outlined-tooltip-icon"
                        name="lds-icon-InfoOutlined"
                        small
                    ></lds-icon>
                </lds-tooltip>
                <div class="badges-wrapper">
                    <lds-tag
                        ?small=${true}
                        icon="lds-icon-CreditCard"
                        .colour="${
                          this.payByCardAvailable ? "primary" : "neutral"
                        }"
                        ?disabled=${!this.payByCardAvailable}
                        >Pay by Card</lds-tag
                    >
                    ${
                      this.bankAccountPaymentProviders?.length > 1
                        ? html`
                              <lds-tag
                                  ?small=${true}
                                  icon="lds-icon-Bank"
                                  .colour="${
                                    this.payByBankAvailable
                                      ? "primary"
                                      : "neutral"
                                  }"
                                  ?disabled=${!this.payByBankAvailable}
                                  >Pay by Bank</lds-tag
                              >
                          `
                        : nothing
                    }
                </div>
            </div>

            ${
              this.companyPaymentSettings?.invoice_reference_visible
                ? html`<lds-input
                      label="${
                        this.companyPaymentSettings?.invoice_reference_label ||
                        "Invoice reference"
                      }"
                      class="pay-request__ref-1"
                      data-cy-create-pay-request-invoice
                      name="invoice_reference"
                      optional
                  >
                  </lds-input>`
                : ""
            }
            ${
              this.companyPaymentSettings?.matter_reference_visible
                ? html`<lds-input
                      label="${
                        this.companyPaymentSettings?.matter_reference_label ||
                        "Matter reference"
                      }"
                      class="pay-request__ref-2"
                      data-cy-create-pay-request-matter
                      name="matter_reference"
                      optional
                  >
                  </lds-input>`
                : ""
            }
            ${
              this.companyPaymentSettings?.custom_reference_visible
                ? html`<lds-input
                      label="${
                        this.companyPaymentSettings?.custom_reference_label ||
                        "Firm reference"
                      }"
                      class="pay-request__custom-ref"
                      data-cy-create-pay-request-custom
                      name="custom_reference"
                      optional
                  >
                  </lds-input>`
                : ""
            }
            <div class="pay-request__doc-upload">
                <label class="pay-request__label">
                    <span>Invoice attachment (optional)</span>
                    <lds-tooltip
                        .text=${"Upload an invoice to attach the request."}
                        placement="top-start"
                    >
                        <lds-icon
                            class="info-outlined-tooltip-icon"
                            name="lds-icon-InfoOutlined"
                        ></lds-icon>
                    </lds-tooltip>
                </label>
                <legl-input-file-upload
                    data-cy-create-pay-request-invoice-upload
                    .maxFiles=${1}
                    .acceptedFiles=${[
                      "pdf",
                      "doc",
                      "docx",
                      "txt",
                      "jpg",
                      "jpeg",
                      "png",
                    ]}
                    upload-url="/api/payment_documents/"
                    param-name="document"
                    is-small="True"
                    @file-added="${() => {
                      this._fileAdded();
                    }}"
                    @file-uploaded="${(e) => {
                      this._addDocument(e.detail.token);
                    }}"
                    @file-removed="${(e) => {
                      this._removeDocument(e.detail.token);
                    }}"
                ></legl-input-file-upload>
            </div>
            <lds-textarea
                label="Internal comments"
                class="pay-request__comment"
                data-cy-create-pay-request-comments
                name="comment"
                help-text="Comments left here are not visible to the contact making the payment"
                maxlength="120"
                .validators="${[new LeglMaxLength(120)]}"
                optional
            >
            </lds-textarea>
            <legl-button
                full-width
                class="pay-request__submit-button ${this.paymentType}"
                data-cy-create-pay-request-create
                type="submit"
                ?disabled=${this.isSubmitting || this.documentIsUploading}
                >Create payment request
            </legl-button>`;
  }

  _fileAdded() {
    this.documentIsUploading = true;
  }

  _addDocument(token) {
    if (token) {
      this.documentUrl = token.url;
      this.filename = token.filename;
      this.documentToken = token.upload_token;
      this.documentIsUploading = false;
    }
  }

  async _removeDocument(token) {
    this.documentIsUploading = false;
    try {
      await del(`${token.url}`);
    } catch (error) {
      if (error instanceof TypeError) {
        // This could happen if a user tries to upload more than one document
        // but is handled by dropzone
        console.log(error);
      } else {
        toastService.showError(error.message);
      }
    }
  }

  get requestInfo() {
    return {
      reference: this.payRequest?.reference,
      id: this.payRequest?.id,
    };
  }

  get successMessage() {
    return html` <div
                class="payCreate__successPanel --reminders"
                data-cy-create-pay-request-success-screen
            >
                <h1
                    class="pay-request_success_title"
                    data-cy-create-pay-success-title
                >
                    Request successfully created!
                </h1>
                <request-success-message
                    class="payCreate__successPanel --wrapper"
                    .selectedContact=${this.selectedContact}
                    .requestInfo=${this.requestInfo}
                    .requestLink=${this.payRequest.link}
                    .reminderStartAfterDays=${this.reminderStartAfterDays}
                    .ccOnRequestEmail=${this.ccOnRequestEmail}
                    .bccEnabled=${this.bccOnRequestEmail}
                    .reminderStartAfterDaysOptions=${
                      this.reminderStartAfterDaysOptions
                    }
                    product="pay"
                    data-cy-request-success-message-request-id=${
                      this.payRequest.id
                    }
                ></request-success-message>
                <legl-card
                    class="details-card card-bg card-border"
                    title="request details"
                >
                    <h1 slot="title" variant="corner">request details</h1>
                    <div class="details-card__content">
                        <div class="details-card__container">
                            <div class="details-card__row">
                                <div class="details-card__item-contact">
                                    <strong>Contact</strong>
                                    <legl-contact-mini-view
                                        class="details-card__value-contact"
                                        contact-name="${
                                          this.payRequest?.first_name
                                        } ${this.payRequest?.last_name}"
                                        .contactEmail=${this.payRequest?.email}
                                    ></legl-contact-mini-view>
                                </div>
                                <div class="details-card__item">
                                    <strong>Created</strong>
                                    <p class="details-card__value">
                                        ${formatDate(
                                          this.payRequest?.created_at,
                                          {
                                            month: "short",
                                            day: "numeric",
                                            year: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: false,
                                          },
                                        )}
                                    </p>
                                </div>
                                <div class="details-card__item">
                                    <strong>Requested by</strong>
                                    <p
                                        class="details-card__value"
                                        data-cy-summary-pay-request-requested-by
                                    >
                                        ${this.requested_by}
                                    </p>
                                </div>
                                ${
                                  this.payRequest.source === "PAY_REQUEST"
                                    ? html` <div
                                          class="details-card__item-methods"
                                      >
                                          <strong
                                              >Available payment methods</strong
                                          >
                                          <div
                                              class="payment-providers-wrapper"
                                          >
                                              ${
                                                this.payRequest.available_payment_providers?.includes(
                                                  "stripe",
                                                )
                                                  ? html`<span
                                                        class="payment-provider"
                                                    >
                                                        <lds-icon
                                                            name="lds-icon-CreditCard"
                                                            small
                                                        ></lds-icon
                                                        >Pay by card
                                                    </span>`
                                                  : nothing
                                              }
                                              ${
                                                this.payRequest.available_payment_providers?.includes(
                                                  "banked",
                                                ) && this.paymentType !== "plan"
                                                  ? html`<span
                                                        class="payment-provider"
                                                    >
                                                        <lds-icon
                                                            name="lds-icon-Bank"
                                                            small
                                                        ></lds-icon
                                                        >Pay by bank
                                                    </span>`
                                                  : nothing
                                              }
                                          </div>
                                      </div>`
                                    : nothing
                                }
                            </div>
                            ${
                              this.scheduleResponse
                                ? html`
                                      <div class="details-card__row">
                                          <div class="details-card__item">
                                              <strong>Plan total</strong>
                                              <p class="details-card__value">
                                                  ${
                                                    html`<legl-currency
                                                      .value=${this.scheduleResponse?.total_amount}
                                                  ></legl-currency>` || "-"
                                                  }
                                              </p>
                                          </div>
                                          <div class="details-card__item">
                                              <strong
                                                  >Monthly instalment</strong
                                              >
                                              <p class="details-card__value">
                                                  ${
                                                    html`<legl-currency
                                                      .value=${this.scheduleResponse?.instalment_amount}
                                                  ></legl-currency>` || "-"
                                                  }
                                              </p>
                                          </div>
                                          <div class="details-card__item">
                                              <strong
                                                  >Remainder instalment</strong
                                              >
                                              <p class="details-card__value">
                                                  ${
                                                    Number.parseFloat(
                                                      this.scheduleResponse
                                                        ?.remainder_instalment_amount,
                                                    )
                                                      ? html`<legl-currency
                                                            .value=${this.scheduleResponse?.remainder_instalment_amount}
                                                        ></legl-currency>`
                                                      : "-"
                                                  }
                                              </p>
                                          </div>
                                          <div class="details-card__item">
                                              <strong
                                                  >Number of instalments</strong
                                              >
                                              <p class="details-card__value">
                                                  ${
                                                    this.scheduleResponse
                                                      ?.number_of_instalments ||
                                                    "-"
                                                  }
                                              </p>
                                          </div>
                                      </div>
                                  `
                                : nothing
                            }
                            <div class="details-card__row">
                                <div class="details-card__item">
                                    <strong
                                        >${
                                          this.companyPaymentSettings
                                            ?.invoice_reference_label ||
                                          "Invoice reference"
                                        }</strong
                                    >
                                    <p class="details-card__value">
                                        ${
                                          this.payRequest?.invoice_reference ||
                                          "-"
                                        }
                                    </p>
                                </div>
                                <div class="details-card__item">
                                    <strong
                                        >${
                                          this.companyPaymentSettings
                                            ?.matter_reference_label ||
                                          "Matter reference"
                                        }</strong
                                    >
                                    <p class="details-card__value">
                                        ${
                                          this.payRequest?.matter_reference ||
                                          "-"
                                        }
                                    </p>
                                </div>
                                ${
                                  this.companyPaymentSettings
                                    ?.custom_reference_visible
                                    ? html`<div class="details-card__item">
                                          <strong
                                              >${
                                                this.companyPaymentSettings
                                                  ?.custom_reference_label ||
                                                "Firm reference"
                                              }</strong
                                          >
                                          <p class="details-card__value">
                                              ${
                                                this.payRequest
                                                  ?.custom_reference || "-"
                                              }
                                          </p>
                                      </div>`
                                    : ""
                                }
                                <div class="details-card__item">
                                    <strong>Invoice attachment</strong>
                                    <p class="details-card__value">
                                        ${
                                          this.payRequest?.has_document
                                            ? html` <a
                                                  class="details-card__invoice-link"
                                                  href=${this.payRequest?.document_url}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                              >
                                                  <lds-icon
                                                      name="lds-icon-VisibilityOn"
                                                  ></lds-icon>
                                                  <span>View</span>
                                              </a>`
                                            : "-"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div class="details-card__comment">
                                <strong>Comment</strong>
                                <p class="details-card__value">
                                    ${this.payRequest?.comment || "-"}
                                </p>
                            </div>
                        </div>
                        <div class="details-card__total">
                            <strong>Total</strong>
                            <p class="details-card__total-value">
                                <legl-currency
                                    .value=${this.payRequest?.amount}
                                    .currency=${this.payRequest?.currency}
                                    .locale=${this.locale}
                                ></legl-currency>
                            </p>
                        </div>
                    </div>
                </legl-card>
            </div>
            <legl-button
                data-cy-pay-request-close-button
                class="pay-request__close-button"
                variant="transparent"
                size="medium"
                @click=${() => this.drawer.hide()}
                >Close
            </legl-button>`;
  }

  onContactChanged(e) {
    if (e?.detail?.uid) {
      this.selectedContact = e.detail;
    } else {
      this.selectedContact = null;
    }
  }

  formCreatesPaymentPlan() {
    // Returns True if the form successfully creates a payment plan
    return this.payRequest && this.totalAmount && this.scheduleResponse;
  }

  formCreatesPayRequest() {
    // Returns True if the form successfully creates a payment plan
    return this.payRequest && !this.totalAmount;
  }

  render() {
    if (this.formCreatesPaymentPlan()) {
      return this.successMessage;
    } else if (this.formCreatesPayRequest()) {
      return this.successMessage;
    }
    return this.currencyCode
      ? html`
                  <legl-contact-selector
                      .selectedContactUid=${this.params.get("contactUid")}
                      .email=${this.params.get("email")}
                      .firstName=${this.params.get("firstName")}
                      .lastName=${this.params.get("lastName")}
                      .hideExpandButton=${true}
                      @contact-changed=${this.onContactChanged}
                  ></legl-contact-selector>
                  <legl-expandable-panel
                      data-cy-create-pay-payment-information
                      title="Payment Information"
                      .isLocked=${true}
                      .isExpanded=${this.selectedContact}
                  >
                      <form
                          @submit="${this.submitForm}"
                          class="pay-request__form"
                          slot="expandable-content"
                      >
                          ${this.paymentInformationFields}
                      </form>
                  </legl-expandable-panel>
              `
      : nothing;
  }
}

customElements.define("legl-pay-request-create", LeglPayRequestCreate);
