<script setup lang="ts">
import { useDrawer } from "@/drawers";
import LeglDrawerComponentImporter from "@/drawers/LeglDrawerComponentImporter.vue";

const drawer = useDrawer();
</script>
<template>
  <div class="drawer-outer-container">
    <Transition name="background-overlay">
      <div
        v-if="drawer.isOpen"
        class="drawer-background-overlay"
        data-testid="drawer-background-overlay"
        @click="drawer.close"
      ></div>
    </Transition>
    <Transition name="drawer">
      <div
        v-if="drawer.isOpen"
        class="drawer-component-container"
        data-testid="drawer-container"
      >
        <LeglDrawerComponentImporter />
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.drawer-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.drawer-component-container {
  position: fixed;
  z-index: 1010;
  top: 0;
  right: 0;
  width: 850px;
  height: 100%;
  transition: all 0.5s ease;
  background-color: $color-neutral-0;
  overflow-y: auto;
}

.drawer-background-overlay {
  position: fixed;
  z-index: 1010;
  background-color: #000c24;
  transition: all 0.5s ease;
  top: 0;
  left: 0;
  opacity: 0.6;
  width: 100vw;
  height: 100vh;
}

.drawer-enter-from,
.drawer-leave-to {
  transform: translateX(850px);
}

.background-overlay-enter-from,
.background-overlay-leave-to {
  opacity: 0;
}
</style>
