import { defineStore } from "pinia";
import { ref } from "vue";
import {
  kebabToPascaleCase,
  pascaleToKebabCase,
} from "../../../../legl-ui/utils/functions";

export const useDrawer = defineStore("use-drawer", () => {
  type Data = {
    componentName: string;
    props?: { [key: string]: string };
  };

  const isOpen = ref(false);
  const componentName = ref<string | null>(null);
  const props = ref<any>(null);

  const urlHash = window.location.hash;

  if (urlHash.includes("#/drawer-open")) {
    try {
      const urlSearchParams = new URLSearchParams(urlHash.split("?")[1]);
      const hashComponentName = kebabToPascaleCase(urlHash.split("/")[2]);
      const hashProps = Object.fromEntries(urlSearchParams.entries());

      componentName.value = hashComponentName;
      props.value = hashProps;
      isOpen.value = true;
    } catch {
      // Do nothing if drawer url is malformed
    }
  }

  function updateURLHash(urlSearchParams: any, componentName: string) {
    let queryString = "";
    if (urlSearchParams) {
      const urlSearchParamsInstance = new URLSearchParams();

      for (const [key, value] of Object.entries(urlSearchParams)) {
        urlSearchParamsInstance.append(key, value as string);
      }
      queryString = `?${urlSearchParamsInstance.toString()}`;
    }
    window.location.hash = `#/drawer-open/${pascaleToKebabCase(
      componentName,
    )}/${queryString}`;
  }

  async function open(data: Data) {
    if (isOpen.value) {
      close();
    }
    updateURLHash(data.props, data.componentName);

    componentName.value = data.componentName;
    props.value = data.props;
    isOpen.value = true;
  }

  function close() {
    componentName.value = null;
    props.value = null;
    isOpen.value = false;
    window.location.hash = "";
    window.dispatchEvent(new Event("legl-drawer:closed"));
  }

  return {
    componentName,
    props,
    isOpen,
    open,
    close,
  };
});
