<script setup lang="ts">
import { useDrawer } from "@/drawers";
import LeglDrawerComponentError from "@/drawers/LeglDrawerComponentError.vue";
import LeglDrawerComponentLoading from "@/drawers/LeglDrawerComponentLoading.vue";
import { defineAsyncComponent } from "vue";
import { pascaleToKebabCase } from "../../../../legl-ui/utils/functions";

const drawer = useDrawer();

const DrawContentComponent = defineAsyncComponent({
  loader: () =>
    import(
      // @ts-ignore
      `./components/${pascaleToKebabCase(drawer?.componentName)}/${
        drawer?.componentName
      }.vue`
    ),
  loadingComponent: LeglDrawerComponentLoading,
  errorComponent: LeglDrawerComponentError,
});
</script>

<template>
  <DrawContentComponent v-bind="drawer.props" />
</template>
