import axios from "axios";
import { LdsToast } from "../../legl-ui/lds-toast";
import cookies from "../../legl-ui/utils/cookies";

import type {
  CustomAxiosError,
  CustomAxiosRequestConfig,
  CustomAxiosResponse,
} from "./types";

export const axiosInstance = axios.create({
  headers: { "X-CSRFTOKEN": cookies.csrf() },
});

axiosInstance.interceptors.response.use(
  (response: CustomAxiosResponse) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.config.successModal?.title) {
      LdsToast.showSuccess({
        title: response.config.successModal.title || "",
        text: response.config.successModal.text || "",
        autoClose: response.config.successModal.autoClose || true,
      });
    }

    return response;
  },
  (error: CustomAxiosError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (axios.isAxiosError(error)) {
      if (error.config.showErrorModal) {
        const errorMessage = "Something went wrong. Please try again later";
        // if (error.response.data.message) {
        //     errorMessage = error.message = error.response.data.message;
        // } else {
        //     switch(error.response.status) {
        //         case 401:
        //             errorMessage = "You are not authorised to make this request";
        //             break;
        //         case 403:
        //             errorMessage = "You are not permitted to make this request";
        //             break;
        //         case 404:
        //             errorMessage = "We could not find the resource you requested";
        //             break;
        //         case 418:
        //             errorMessage = "The server refuses the attempt to brew coffee with a teapot";
        //             break;
        //         case 503:
        //             errorMessage = "Service currently unavailable, please try again later";
        //             break;
        //     }
        // }
        LdsToast.showError({
          title: errorMessage,
          text: "",
          autoClose: true,
        });
      }
    }

    return Promise.reject(error);
  },
);

export const http = {
  get: <T>(url: string, config?: CustomAxiosRequestConfig) =>
    axiosInstance.get<T>(url, config),
  delete: (url: string, config?: CustomAxiosRequestConfig) =>
    axiosInstance.delete(url, config),
  head: (url: string, config?: CustomAxiosRequestConfig) =>
    axiosInstance.head(url, config),
  options: (url: string, config?: CustomAxiosRequestConfig) =>
    axiosInstance.options(url, config),
  post: <D, R>(url: string, data?: D, config?: CustomAxiosRequestConfig) =>
    axiosInstance.post<R>(url, data, config),
  put: <D, R>(url: string, data?: D, config?: CustomAxiosRequestConfig) =>
    axiosInstance.put<R>(url, data, config),
  patch: <D, R>(url: string, data?: D, config?: CustomAxiosRequestConfig) =>
    axiosInstance.patch<R>(url, data, config),
  postForm: <D, R>(url: string, data?: D, config?: CustomAxiosRequestConfig) =>
    axiosInstance.postForm<R>(url, data, config),
  putForm: <D, R>(url: string, data?: D, config?: CustomAxiosRequestConfig) =>
    axiosInstance.putForm<R>(url, data, config),
  patchForm: <D, R>(url: string, data?: D, config?: CustomAxiosRequestConfig) =>
    axiosInstance.patchForm<R>(url, data, config),
};
