import { Validator } from "@lion/form-core";
import { LitElement, html, nothing } from "lit";
import {
  LeglMaxAmount,
  LeglMinAmount,
  LeglRequired,
} from "../../../../../../legl-ui/lds-input";
import { LdsInputAmount } from "../../../../../../legl-ui/lds-input-amount";
import "../../../../../../legl-ui/lds-radio";
import "../../../../../../legl-ui/lds-tag";
import {
  addMonths,
  formatDate,
} from "../../../../../../legl-ui/utils/functions.js";

class CustomLdsInputAmount extends LdsInputAmount {
  // lds-input will be validated only when submitted by default
  _showFeedbackConditionFor() {
    return this.submitted || this.touched;
  }
}

customElements.define("custom-lds-input-amount", CustomLdsInputAmount);

class InstalmentAmountValidator extends Validator {
  execute = (modelValue, [totalAmount, minimumPayment]) => {
    return modelValue > totalAmount - minimumPayment;
  };

  static getMessage(data) {
    return `There must be at least 2 instalments.`;
  }
}

class MaxNumOfInstalments extends Validator {
  execute = (modelValue, planBreakdown) => {
    return (
      planBreakdown.instalmentCount() +
        !!Number.parseFloat(planBreakdown.finalInstalmentAmount()) >
      50
    );
  };

  static getMessage(data) {
    return `The maximum number of instalments is 50.`;
  }
}

export class PaymentTypeSelector extends LitElement {
  constructor() {
    super();
    this.paymentType = "single";
    this.scheduledPaymentsEnabled = false;
    this.currency = null;
  }

  static get properties() {
    return {
      paymentType: { state: true },
      totalAmount: { state: true },
      instalmentAmount: { state: true },
      scheduledPaymentsEnabled: { attribute: false },
      setCurrentAmount: { attribute: false },
      setPaymentType: { attribute: false },
      minimumPayment: { attribute: false },
      currency: { attribute: false },
      locale: { attribute: false },
      params: {
        type: URLSearchParams,
        attribute: false,
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.handlePaymentTypeChange(
      this.params.get("payment_plan") ? "plan" : "single",
    );
  }

  createRenderRoot() {
    return this;
  }

  handlePaymentTypeChange(paymentType) {
    this.paymentType = paymentType;
    this.setPaymentType(paymentType);
    if (paymentType === "single") {
      this.setCurrentAmount(this.totalAmount);
    } else {
      this.setCurrentAmount(this.instalmentAmount);
    }
  }

  calculateFinalInstalment(totalAmount, instalmentAmount) {
    let remainder = totalAmount % instalmentAmount;
    if (remainder > 0 && remainder < this.minimumPayment) {
      remainder = remainder + instalmentAmount;
    }
    return remainder;
  }

  get enableCreationOfPaymentPlans() {
    return waffle.flag_is_active("adyen-payment-plans");
  }

  get planBreakdown() {
    if (this.paymentType === "single") {
      return null;
    }

    const { totalAmount, instalmentAmount } = this;

    const breakdown = () => {
      const places = (fixed, amountFunc) => () => amountFunc().toFixed(fixed);
      // higher order function to decorate an amount function to return in pounds
      const pounds = (amountFunc) => () => Math.round(amountFunc() * 100) / 100;

      const remainderAmount = pounds(() =>
        this.calculateFinalInstalment(totalAmount, instalmentAmount),
      );
      const divisibleAmount = pounds(() => totalAmount - remainderAmount());

      const valid = () => {
        return {
          state:
            this.totalAmount &&
            this.totalAmount > 0 &&
            this.instalmentAmount &&
            this.instalmentAmount >= this.minimumPayment &&
            this.totalAmount >= this.instalmentAmount + this.minimumPayment,
          message: () => {
            if (this.instalmentAmount < this.minimumPayment) {
              return "Instalment amounts need to be at least £5.00";
            }
            return "Enter a total and an instalment amount to calculate number of payments.";
          },
        };
      };

      return {
        instalment: places(
          2,
          pounds(() => instalmentAmount),
        ),
        total: places(
          2,
          pounds(() => totalAmount),
        ),
        instalmentCount: () => Math.round(divisibleAmount() / instalmentAmount),
        finalInstalmentAmount: places(2, () => remainderAmount()),
        valid,
      };
    };

    return breakdown();
  }

  getPaymentBreakdown() {
    const breakdown = this.planBreakdown;
    const instalmentCount = breakdown.instalmentCount();
    const remainder = breakdown.finalInstalmentAmount();
    const today = new Date();
    const endDate = addMonths(
      today,
      instalmentCount - 1 + (!!Number(remainder) ? 1 : 0),
    );
    return html`
            <div class="payment-type-selector-plan_breakdown">
                <span>Plan Breakdown:</span>
                ${
                  breakdown.valid().state
                    ? html`
                        <p>
                            <span>${instalmentCount}</span>
                                monthly
                                ${
                                  instalmentCount > 1
                                    ? "instalments"
                                    : "instalment"
                                }
                              of
                            <legl-currency
                                .value=${breakdown.instalment()}
                                .currency=${this.currency}
                            ></legl-currency>
                        </p>
                        ${
                          remainder > 0
                            ? html` <p>
                                      <span>1</span> monthly instalment of
                                      <legl-currency
                                          .value=${remainder}
                                          .currency=${this.currency}
                                      ></legl-currency>
                                  </p>`
                            : nothing
                        }
                        <p>First payment date:<br><span>Today<span></p>
                        <p>Final payment date:<br><span>${formatDate(
                          endDate.toString(),
                          {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          },
                        )}<span></p>
                        `
                    : html`<p>
                          ${breakdown.valid().message()}
                      </p>`
                }
            </div>
        `;
  }

  getSinglePaymentFields(name) {
    return html`
            <lds-input-amount
              name=${name || "amount"}
              class="source-input pay-request__amount"
              data-cy-create-pay-request-amount
              type="number"
              .currencyCode=${this.currency}
              .locale=${this.locale}
              .modelValue=${this.totalAmount}
              label="Payment total"
              @model-value-changed="${(e) => {
                this.totalAmount = Number.parseFloat(e.target.modelValue, 10);
                if (!name) {
                  this.setCurrentAmount(this.totalAmount);
                }
              }}"
              .validators=${[
                new LeglRequired(),
                new LeglMinAmount(this.minimumPayment, {
                  currency: this.currency,
                  locale: this.locale,
                }),
                new LeglMaxAmount(this.bankedPaymentLimit || 1000000, {
                  currency: this.currency,
                  locale: this.locale,
                }),
              ]}
            >
            </lds-input-amount>
        `;
  }

  getPaymentPlanFields() {
    return html`
            <div class="payment-type-selector-payment-plan-section">
                <div class="payment-type-selector-plan_amounts">
                    ${this.getSinglePaymentFields("total_amount")}
                    <custom-lds-input-amount
                        name="amount"
                        class="source-input pay-request__amount"
                        data-cy-create-pay-monthly-instalment-amount
                        type="number"
                        label="Monthly instalment"
                        .modelValue=${this.instalmentAmount}
                        @model-value-changed="${(e) => {
                          this.instalmentAmount = Number.parseFloat(
                            e.target.modelValue,
                          );
                          this.setCurrentAmount(this.instalmentAmount);
                        }}"
                        .validators=${[
                          new LeglRequired(),
                          new LeglMinAmount(this.minimumPayment, {
                            currency: this.currency,
                            locale: this.locale,
                          }),
                          new LeglMaxAmount(
                            this.bankedPaymentLimit || 1000000,
                            { currency: this.currency, locale: this.locale },
                          ),
                          new InstalmentAmountValidator([
                            this.totalAmount,
                            this.minimumPayment,
                          ]),
                          new MaxNumOfInstalments(this.planBreakdown),
                        ]}
                    ></custom-lds-input-amount>
                </div>
                <div>${this.getPaymentBreakdown()}</div>
            </div>
        `;
  }

  style() {
    // we can't use the external stylesheet because this component it's being rendered inside of the shadowroot of <legl-pay-request-create>
    // so using the <style> tag is the next best thing.
    return html`
            <style>
                payment-type-selector {
                    color: var(--lds-colour-neutral-800);
                }

                payment-type-selector lds-radio-group {
                    margin-bottom: var(--lds-spacing-s);
                }

                payment-type-selector
                    .payment-type-selector-payment-plan-section {
                    display: flex;
                    gap: var(--lds-spacing-s);
                }

                payment-type-selector
                    .payment-type-selector-payment-plan-section
                    > div {
                    width: 50%;
                }

                payment-type-selector
                    .payment-type-selector-plan_amounts
                    lds-input-amount,
                payment-type-selector
                    .payment-type-selector-plan_amounts
                    custom-lds-input-amount {
                    margin-bottom: var(--lds-spacing-s);
                }

                payment-type-selector .payment-type-selector-plan_breakdown {
                    background-color: var(--lds-colour-neutral-100);
                    padding: 12px var(--lds-spacing-s) 12px var(--lds-spacing-s);
                    border: 1px solid var(--lds-colour-neutral-400);
                    border-radius: 4px;
                }

                payment-type-selector
                    .payment-type-selector-plan_breakdown
                    legl-currency,
                payment-type-selector
                    .payment-type-selector-plan_breakdown
                    span {
                    font-weight: var(--lds-typography-weight-bold);
                }

                payment-type-selector .payment-plan-label {
                    display: flex;
                    align-items: center;
                    gap: var(--lds-spacing-xxs);
                }
            </style>
        `;
  }

  render() {
    return html`
            ${this.style()}
            <div class="payment-type-selector">
                <lds-radio-group
                    name="payment_type"
                    label="Payment Type"
                    horizontal
                    @model-value-changed="${(e) => {
                      if (this.scheduledPaymentsEnabled) {
                        this.handlePaymentTypeChange(e.target.modelValue);
                      }
                    }}"
                >
                    <lds-radio
                        label="Single Payment"
                        .choiceValue=${"single"}
                        ?checked=${this.paymentType === "single"}
                    ></lds-radio>
                    ${
                      this.enableCreationOfPaymentPlans
                        ? html`<lds-radio
                         label="Payment Plan"
                         .choiceValue=${"plan"}
                         ?disabled=${!this.scheduledPaymentsEnabled}
                         ?checked=${this.paymentType === "plan"}
                     ></lds-radio>`
                        : nothing
                    }

                </lds-radio-group>
                ${
                  this.paymentType === "single"
                    ? this.getSinglePaymentFields()
                    : this.getPaymentPlanFields()
                }
            </div>
        `;
  }
}

customElements.define("payment-type-selector", PaymentTypeSelector);
