import DOMPurify from "dompurify";
import Dropzone from "dropzone";
import { css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import "../../legl-ui/icon";
import { neutral } from "../../legl-ui/lds-colours";
import { isIE } from "../../legl-ui/utils";
import { DropzoneStyles } from "./utils/dropzone.js";

Dropzone.autoDiscover = false;

export class LeglFileUploadInput extends DropzoneStyles {
  render() {
    return html`
            <slot name="start"></slot>
            <div class=${classMap(this.uploadClasses)}>
                <div id="dropzone" class="dropzone" data-cy-file-upload>
                    <div class="max-files-warning__message">
                        <p>
                            You have reached the maximum number of files, please
                            remove a file to upload a new one.
                        </p>
                    </div>
                </div>
                <div class="supported-files">
                    Supported files types:
                    ${this.acceptedFiles.map(
                      (type, index) =>
                        html`'${type}'${
                          index < this.acceptedFiles.length - 1 ? ", " : ""
                        }`,
                    )}
                </div>
                <slot name="input"></slot>
            </div>
        `;
  }

  static get styles() {
    return [
      super.dropzoneStyles,
      css`
                :host {
                    display: block;
                    margin: 30px 0;
                }

                :host .small {
                    font-size: 12px;
                }

                :host h4 {
                    margin: 0;
                }
                :host h3 {
                    margin: 5px 0;
                }

                :host i {
                    font-weight: normal;
                }

                :host p {
                    margin: 6px 0 24px 0px;
                }

                :host a {
                    color: var(--legl-grey-dark);
                }

                :host .supported-files {
                    display: flex;
                    justify-content: flex-end;
                    font-style: italic;
                    font-size: 10px;
                    padding-right: 2px;
                }

                :host .dropzone {
                    background-color: ${neutral["100"]};
                    border: 1px dashed ${neutral["800"]};
                    min-height: 76px;
                    padding: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    transition: border 0.25s ease-in-out,
                        background-color 0.25s ease-in-out;
                    position: relative;
                    overflow: hidden;
                }

                :host .small .dropzone {
                    min-height: 48px;
                    padding: 5px;
                }

                :host .max-files-warning .dropzone {
                    border: 1px solid #840800;
                    background-color: #fee7ea;
                }

                :host .max-files-warning__message {
                    display: block;
                    visibility: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    background-color: #840800;
                    color: white;
                    font-size: 14px;
                    padding: 5px 10px;
                    transition: visibility 0.25s ease-in-out;
                }

                :host .max-files-warning .max-files-warning__message {
                    visibility: visible;
                }

                :host .max-files-warning__message p {
                    margin: 0;
                }
                :host .dz-message {
                    margin: 0;
                }

                :host .dropzone .dz-message .dz-button {
                    color: ${neutral["800"]};
                    text-align: left;
                }

                :host .legl-dropzone-message {
                    display: flex;
                }

                :host .legl-dropzone-message:before {
                    font-family: "legl-icon";
                    content: var(--legl-icon-upload-o);
                    margin: auto 10px;
                    font-size: 33px;
                }

                :host .small .legl-dropzone-message:before {
                    font-size: 20px;
                }

                :host .dropzone .dz-preview {
                    margin: 10px;
                }

                :host .dropzone .dz-preview .dz-image {
                    border-radius: 8px;
                    border: 2px solid ${neutral["800"]};
                    height: 80px;
                    margin: 0 0 5px 0;
                }

                :host .dz-filename {
                    top: 57px;
                    position: relative;
                    color: ${neutral["800"]};
                    background-color: transparent;
                }

                :host .dz-size {
                    display: none;
                }

                :host .dropzone .dz-preview .dz-details {
                    opacity: 1;
                    padding: 2em 0em;
                }

                :host .dropzone .dz-preview:hover .dz-image img {
                    -webkit-transform: scale(1, 1);
                    -moz-transform: scale(1, 1);
                    -ms-transform: scale(1, 1);
                    -o-transform: scale(1, 1);
                    transform: scale(1, 1);
                    -webkit-filter: blur(0px);
                    filter: blur(0px);
                }

                :host .dropzone .dz-preview.dz-image-preview {
                    background: transparent;
                }

                :host .dz-details:after {
                    font-family: "legl-icon";
                    content: var(--legl-icon-confirm-circle-o);
                    bottom: 15px;
                    position: relative;
                    color: ${neutral["800"]};
                    font-size: 32px;
                }

                :host .dropzone .dz-preview .dz-success-mark,
                .dropzone .dz-preview .dz-error-mark {
                    display: none;
                }

                :host .dz-remove {
                    height: 0;
                    visibility: hidden;
                    text-decoration: none;
                }

                :host .is-ie .dz-remove {
                    height: auto;
                    visibility: visible;
                    position: absolute;
                    top: -20px;
                }

                :host .dz-remove.dz-remove:before {
                    font-family: "legl-icon";
                    content: var(--legl-icon-trash);
                    visibility: visible;
                    display: block;
                    bottom: 77px;
                    z-index: 1001;
                    right: 2px;
                    position: absolute;
                    color: var(--legl-red);
                    text-decoration: none;
                    font-size: 12px;
                    width: 20px;
                }

                :host .dropzone .dz-preview .dz-progress .dz-upload {
                    background: ${neutral["800"]};
                }

                :host .dropzone .dz-preview .dz-progress {
                    height: 10px;
                    top: 70%;
                    background: transparent;
                }

                :host .dropzone.dz-started .dz-message {
                    display: block;
                    width: 100%;
                    margin-bottom: 10px;
                }

                .dropzone .dz-preview.dz-processing {
                    z-index: 1;
                }

                .dropzone .dz-preview .dz-error-message::after {
                    display: none;
                }

                .dropzone .dz-preview .dz-error-message {
                    width: 120px;
                    background: #fee7ea;
                    color: #840800;
                    left: 0px;
                    height: 80px;
                    opacity: 1;
                    border: 2px solid;
                    text-align: center;
                    overflow: hidden;
                    font-size: 1.3vh;
                    line-height: 14px;
                    top: 0px;
                }

                .dropzone .dz-preview.dz-error .dz-error-message {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .dropzone .dz-preview .dz-remove:hover {
                    text-decoration: none;
                }
            `,
    ];
  }

  constructor() {
    super();
    this.dropzone = null;
    this.passwordDisclaimer = true;
    this._uploadMessage = `
            <div class="legl-dropzone-message">
                <div>
                    <h4>Upload File</h4>
                    <i>Drag & drop or click here to upload files</i><br>
                    <small>Do not upload password protected PDFs</small>
                </div>
            </div>
        `;
    this.acceptedFiles = ["pdf", "jpeg", "jpg", "png", "gif", "tiff"];
    this.extraTokenData = {};
    this.autoProcessQueue = true;
  }

  static get properties() {
    return {
      uploadUrl: {
        type: String,
        attribute: "upload-url",
      },
      acceptedFiles: {
        type: Array,
        attribute: "accepted-files",
      },
      uploadMessage: {
        type: String,
        attribute: "upload-message",
      },
      initialValue: {
        type: Array,
      },
      maxFiles: {
        type: Number,
      },
      maxFilesWarning: {
        type: Boolean,
        attribute: false,
      },
      passwordDisclaimer: {
        type: Boolean,
        attribute: false,
      },
      paramName: {
        type: String,
        attribute: "param-name",
      },
      extraTokenData: {
        type: Object,
        attribute: "extra-token-data",
      },
      autoProcessQueue: {
        type: Boolean,
      },
      isSmall: {
        type: String,
        attribute: "is-small",
      },
    };
  }

  get inputNode() {
    return this.querySelector("input[slot='input']");
  }

  get hasInput() {
    return !!this.inputNode;
  }

  set uploadMessage(message) {
    this._uploadMessage = DOMPurify.sanitize(message);
  }

  get uploadMessage() {
    if (this.passwordDisclaimer === false) {
      this._uploadMessage = `
                <div class="legl-dropzone-message">
                    <div>
                        <h4>Upload File</h4>
                        <i>Drag & drop or click here to upload files</i><br>
                    </div>
                </div
            `;
    }

    return this._uploadMessage;
  }

  get required() {
    return this.hasAttribute("required");
  }

  get isValid() {
    if (this.required && this.dropzone?.files?.length === 0) {
      return false;
    }

    return this.dropzone?.files?.every((file) => file.status === "success");
  }

  get uploadClasses() {
    return {
      "is-ie": isIE(),
      "max-files-warning": this.maxFilesWarning,
      small: this.isSmall,
    };
  }

  storeToken(token) {
    const newToken =
      typeof token === "object" ? { ...this.extraTokenData, ...token } : token;
    if (this.hasInput) {
      this.inputNode.value = JSON.stringify(newToken);
    }
    this.dispatchEvent(
      new CustomEvent("file-uploaded", {
        detail: { token: newToken },
        bubbles: true,
      }),
    );
  }

  removeToken(token) {
    if (this.hasInput) {
      this.inputNode.value = "";
    }
    this.dispatchEvent(
      new CustomEvent("file-removed", {
        detail: {
          token:
            typeof token === "object"
              ? { ...this.extraTokenData, ...token }
              : token,
        },
      }),
    );
  }

  addedFile(e) {
    this.dispatchEvent(
      new CustomEvent("file-added", {
        detail: e,
      }),
    );
  }

  displayMaxFilesWarning() {
    this.maxFilesWarning = true;
    setTimeout(() => {
      this.maxFilesWarning = false;
    }, 3000);
  }

  firstUpdated(_changedProperties) {
    const component = this;
    this.dropzone = new Dropzone(
      this.shadowRoot.querySelector("div#dropzone"),
      {
        url: this.uploadUrl || "/",
        headers: {
          "X-CSRFTOKEN": CSRF_TOKEN,
        },
        timeout: 0, // https://stackoverflow.com/a/46488162/15322958
        dictDefaultMessage: this.uploadMessage,
        addRemoveLinks: true,
        acceptedFiles: `.${this.acceptedFiles.join(", .")}`,
        maxFiles: this.maxFiles,
        resizeWidth: 2480, // A4 page portrait at 300 DPI
        hiddenInputContainer: this.shadowRoot,
        paramName: this.paramName || "file",
        autoProcessQueue: this.autoProcessQueue,
        init() {
          if (component.initialValue && Array.isArray(component.initialValue)) {
            component.initialValue.forEach((document) => {
              this.displayExistingFile({
                name: document.name,
                token: document,
              });
              component.storeToken(document);
            });
          }
        },
      },
    );

    this.dropzone.on("addedfile", (e) => {
      this.addedFile(e);
    });

    this.dropzone.on("success", (file, token) => {
      file.token = token;
      this.storeToken(token);
    });

    this.dropzone.on("removedfile", function (file) {
      component.removeToken(file.token);
      this.setupEventListeners();
    });

    this.dropzone.on("maxfilesexceeded", function (file) {
      this.removeFile(file);
      component.displayMaxFilesWarning();
    });
  }
}

customElements.define("legl-input-file-upload", LeglFileUploadInput);
