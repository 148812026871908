<script setup lang="ts">
import { useDrawer } from "@/drawers";
import LeglDrawer from "@/drawers/LeglDrawer.vue";
import { router } from "@/router";
import { computed } from "vue";
import { RouterView } from "vue-router";
import LeglLogo from "../../components/LeglLogo.vue";

const showNav = computed(() => !router.currentRoute.value.meta.isAuth);

const drawer = useDrawer();
</script>

<template>
  <main>
    <lds-nav v-if="showNav" id="main-navigation" data-cy-dashboard-navigation>
      <div slot="logo">
        <LeglLogo />
      </div>
    </lds-nav>
    <div class="route-content" :class="{ 'show-nav': showNav }">
      <RouterView :key="router.currentRoute.value.path" />
    </div>
    <LeglDrawer />
  </main>
</template>

<style lang="scss">
body {
  font-family: "Lato", sans-serif;
  background-color: var(--lds-colour-neutral-100);
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
}

.modal-manager-modal {
  width: 90%;
  max-width: 990px;
  background: white;
}

.modal .modal-dialog.modal-manager-modal .modal-content {
  max-width: unset;
  width: unset;
}

.global-overlays {
  /* Mirror the old bootstrap modals z-index for the new lion modals as we have other things that rely on the same z-indexing */
  z-index: 1050 !important;
}

/* Doubled up class for specificity hack to avoid !important */
.global-overlays__overlay-container--center.global-overlays__overlay-container--center {
  align-items: start;
  padding: 3rem 0;
  overflow-y: auto;
}

.global-overlays__overlay-container--center {
  justify-content: center;
  align-items: center;
}

.global-overlays__overlay-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.route-content {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  &.show-nav {
    margin-top: 60px;
    padding-right: 39px;
    padding-left: 39px;
    margin-bottom: $spacing-xl;
  }

  @media screen and (min-width: 1030px) {
    &.show-nav {
      padding-left: 265px;
      margin-top: 0;
    }
  }
}
</style>
