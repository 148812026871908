import { router } from "@/router";
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { I18NextVue, i18next } from "../../../legl-ui/translations";
import App from "./App.vue";

declare global {
  interface Window {
    APP_BASE_URL: string;
  }
}

if (window.APP_BASE_URL) {
  import.meta.env.BASE_URL = window.APP_BASE_URL;
}

const pinia = createPinia();

const app = createApp(App);
// @ts-ignore
if (HEROKU_APP_NAME) {
  Sentry.init({
    app,
    // @ts-ignore
    environment: HEROKU_APP_NAME.replace(/^(legl-)/, ""),
    dsn: "https://d42c9333aa9224cda51aa493fef54fd0@o4507180312297472.ingest.de.sentry.io/4507180805980241",
    integrations: [],
  });
}

// @ts-ignore
app.use(router);
app.use(pinia);
app.use(I18NextVue, {
  i18next,
});

app.mount("#app");
