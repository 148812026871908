import { createReducer } from "@reduxjs/toolkit";
import {
  filterSelectedRows,
  selectCurrentPageRows,
  selectRow,
  setSelectableRows,
  unselectAllRows,
  unselectCurrentPageRows,
  unselectRow,
} from "../../actions/table-row-selector";

const initialState = {
  selectedRows: [],
  selectableRows: [],
};

const tableRowSelectorReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSelectableRows, (state, action) => {
    state.selectableRows = action.payload;
    return state;
  });
  builder.addCase(selectRow, (state, action) => {
    const rowAlreadySelected = state.selectedRows.find(
      (selectedRow) => selectedRow.reference === action.payload.reference,
    );
    if (!rowAlreadySelected) {
      const selectedRow = state.selectableRows.find(
        (selectableRow) => selectableRow.reference === action.payload.reference,
      );
      state.selectedRows = [...state.selectedRows, selectedRow];
    }
    return state;
  });
  builder.addCase(unselectRow, (state, action) => {
    state.selectedRows = state.selectedRows.filter(
      (selectedRow) => selectedRow.reference !== action.payload.reference,
    );
    return state;
  });
  builder.addCase(selectCurrentPageRows, (state) => {
    state.selectableRows.forEach((row) => {
      const rowAlreadySelected = state.selectedRows.find(
        (selectedRow) => selectedRow.reference === row.reference,
      );
      if (!rowAlreadySelected) {
        state.selectedRows.push(row);
      }
    });
    return state;
  });
  builder.addCase(unselectCurrentPageRows, (state) => {
    state.selectedRows = state.selectedRows.filter(
      (selectedRow) =>
        !state.selectableRows.find(
          (selectableRow) => selectedRow.reference === selectableRow.reference,
        ),
    );
    return state;
  });
  builder.addCase(unselectAllRows, (state) => {
    state.selectedRows = [];
    return state;
  });
  builder.addCase(filterSelectedRows, (state, action) => {
    const { currentColumnFilters, columnName } = action.payload;

    state.selectedRows = state.selectedRows.filter((selectedRow) =>
      currentColumnFilters.includes(selectedRow[columnName].toString()),
    );
    return state;
  });
});

export default tableRowSelectorReducer;
