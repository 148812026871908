import type { AxiosResponse } from "axios";
import { http } from "../../../../../request-manager/axios-http";

export function getBusinessDetails(businessId: string): Promise<AxiosResponse> {
  return http.get(`/api/businesses/${businessId}/`);
}

export function getBusinessSanctionsMonitoringAlerts(
  businessId: number,
): Promise<AxiosResponse> {
  return http.get(`/api/businesses/${businessId}/ongoing_monitoring/`);
}

export function setOngoingMonitoring(
  businessId: number,
  enabled: string,
): Promise<AxiosResponse> {
  return http.patch(
    `/api/businesses/${businessId}/monitoring/`,
    {
      enabled,
    },
    {
      showErrorModal: true,
    },
  );
}

type NewSanctionsCheckPostData = {
  business_id: number;
  created_automatically: boolean;
};

type NewSanctionsCheckResponse = {
  id: number;
  business_id: string;
  created_at: string;
  status: string;
};

type NewSanctionsCheckAsyncResponse = {
  job_id: string;
};

export function generateNewSanctionCheck(
  postData: NewSanctionsCheckPostData,
): Promise<AxiosResponse<NewSanctionsCheckResponse>> {
  return http.post<NewSanctionsCheckPostData, NewSanctionsCheckResponse>(
    `/api/sanctions_check/`,
    postData,
    {
      showErrorModal: true,
    },
  );
}

export function generateNewSanctionCheckAsync(
  postData: NewSanctionsCheckPostData,
): Promise<AxiosResponse<NewSanctionsCheckAsyncResponse>> {
  return http.post<NewSanctionsCheckPostData, NewSanctionsCheckAsyncResponse>(
    `/api/sanctions_check/async_create/`,
    postData,
    {
      showErrorModal: true,
    },
  );
}

type RunCompanyReportPost = {
  creditsafe_company_id: string;
  country_iso_code: string;
  business: string;
  created_by: string;
  company: string;
  created_automatically: boolean;
};

export type RunCompanyReportResponse = {
  creditsafe_company_id: string;
  country_iso_code: string;
  business: number;
  company: number;
  created_at: string;
  created_by: number;
  created_automatically: boolean;
  reference: string;
};

export function runCompanyReport(
  postData: RunCompanyReportPost,
): Promise<AxiosResponse<RunCompanyReportResponse>> {
  return http.post<RunCompanyReportPost, RunCompanyReportResponse>(
    "/creditsafe_company_report/",
    postData,
  );
}

export function disableCompanyReportOngoingMonitoring(
  businessId: string,
): Promise<AxiosResponse> {
  return http.post(
    `/api/businesses/${businessId}/disable_company_report_ongoing_monitoring/`,
  );
}
