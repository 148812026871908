import { createRouter, createWebHistory } from "vue-router";
import { userConfig } from "../../../../apps/lawyers-app/static-src/js/config/user-config.js";
import { drawers } from "../../../../apps/lawyers-app/static-src/js/dialog-manager/drawer-manager.js";
import { snakeToCamelCase } from "../../../../legl-ui/utils/functions.js";
import { bootstrapVueApp } from "../config.js";

export const RouteNames = {
  AUTH: {
    LOGIN: "login",
    FORGOTTEN_PASSWORD: "forgotten-password",
    RESET_PASSWORD: "reset-password",
    CREATE_ACCOUNT: "create-account",
  },
  SETTINGS: {
    MY_ACCOUNT: "my-account",
    INTEGRATIONS: "integrations",
  },
  PAY: {
    PAYMENT_PLANS: "payment-plans",
    TRANSACTIONS: "transactions",
  },
  BUSINESSES: {
    LIST: "businesses-list",
    DETAIL: "business-detail",
    SEARCH: "business-search",
    SANCTIONS_REPORT: "business-sanctions-report",
    SANCTIONS_DETAILS: "business-sanctions-detail",
  },
  ENGAGE: {
    LIST: "engage-list",
  },
  CONFLICTS: {
    LIST: "check-list",
    DETAIL: "check-details",
    CREATE: "check-create",
    COMING_SOON: "coming-soon",
    ADD_INDIVIDUAL: "add-individual",
    ADD_ADDITIONAL_INDIVIDUAL: "add-additional-individual",
    ADD_ADDITIONAL_BUSINESS: "add-additional-business",
    ADD_BUSINESS: "add-business",
  },
  SCREENING: {
    DETAIL: "screening-details",
  },
  MONITORING: {
    LIST: "monitoring-list",
  },
  COMPANY_REPORT: {
    CREATE: "company-report-create",
  },
};

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: RouteNames.AUTH.LOGIN,
      path: "/login",
      alias: ["/accounts/login", "/"],
      component: () => import("@/views/auth/login/LeglLogin.vue"),
      meta: {
        title: "Login",
        isAuth: true,
      },
    },
    {
      name: RouteNames.AUTH.CREATE_ACCOUNT,
      path: "/create/:uuid",
      component: () =>
        import("@/views/auth/create-account/LeglCreateAccount.vue"),
      meta: {
        title: "Login",
        isAuth: true,
      },
    },
    {
      name: RouteNames.AUTH.FORGOTTEN_PASSWORD,
      path: "/forgot-password",
      component: () =>
        import("@/views/auth/forgot-password/LeglForgotPassword.vue"),
      meta: {
        title: "Forgot Password",
        isAuth: true,
      },
    },
    {
      name: RouteNames.AUTH.RESET_PASSWORD,
      path: "/forgot-password/password_reset_confirm/:uidb64/:token",
      component: () =>
        import("@/views/auth/reset-password/LeglResetPassword.vue"),
      meta: {
        title: "Reset Password",
        isAuth: true,
      },
    },
    {
      name: RouteNames.SETTINGS.MY_ACCOUNT,
      path: "/settings",
      component: () => import("@/views/settings/my-account/MyAccount.vue"),
      meta: {
        title: "My Account",
      },
    },
    {
      name: RouteNames.SETTINGS.INTEGRATIONS,
      path: "/settings-integrations",
      component: () => import("@/views/settings/integrations/Integrations.vue"),
      meta: {
        title: "Integrations",
      },
    },
    {
      name: RouteNames.PAY.PAYMENT_PLANS,
      path: "/payment-plans",
      component: () => import("@/views/pay/payment-plans/PaymentPlans.vue"),
      meta: {
        title: "Payment Plans",
      },
      beforeEnter: (to, from, next) => {
        if (!to.query.limit) {
          next({ ...to, query: { ...to.query, limit: 10 } });
        } else {
          next();
        }
      },
    },
    {
      name: RouteNames.PAY.TRANSACTIONS,
      path: "/payments",
      component: () => import("@/views/pay/transactions/TransactionsPage.vue"),
      meta: {
        title: "Transactions",
      },
      beforeEnter: (to, from, next) => {
        if (!to.query.limit) {
          next({ ...to, query: { ...to.query, limit: 10 } });
        } else {
          next();
        }
      },
    },
    {
      name: RouteNames.CONFLICTS.LIST,
      path: "/conflicts/checks",
      props: true,
      component: () => import("@/views/conflicts/check-list/CheckList.vue"),
      beforeEnter: (to, from, next) => {
        if (waffle.flag_is_active("conflict_checks")) {
          next();
        } else {
          next({ name: RouteNames.CONFLICTS.COMING_SOON });
        }
      },
      meta: {
        title: "Conflicts",
      },
    },
    {
      name: RouteNames.CONFLICTS.DETAIL,
      path: "/conflicts/checks/:id",
      props: true,
      component: () =>
        import("@/views/conflicts/check-details/CheckDetails.vue"),
      beforeEnter: (to, from, next) => {
        if (waffle.flag_is_active("conflict_checks")) {
          next();
        } else {
          next({ name: RouteNames.CONFLICTS.COMING_SOON });
        }
      },
      meta: {
        title: "Check Results",
      },
    },
    {
      name: RouteNames.CONFLICTS.CREATE,
      path: "/conflicts/create",
      props: true,
      component: () => import("@/views/conflicts/check-create/CheckCreate.vue"),
      beforeEnter: (to, from, next) => {
        if (waffle.flag_is_active("conflict_checks")) {
          next();
        } else {
          next({ name: RouteNames.CONFLICTS.COMING_SOON });
        }
      },
      meta: {
        title: "Create Check",
      },
    },
    {
      name: RouteNames.CONFLICTS.ADD_INDIVIDUAL,
      path: "/conflicts/create/add-individual/:id?",
      props: true,
      component: () =>
        import("@/views/conflicts/check-add-individual/CheckAddIndividual.vue"),
      beforeEnter: (to, from, next) => {
        if (waffle.flag_is_active("conflict_checks")) {
          next();
        } else {
          next({ name: RouteNames.CONFLICTS.COMING_SOON });
        }
      },
      meta: {
        title: "Check Create - Add individual",
      },
    },
    {
      name: RouteNames.CONFLICTS.ADD_ADDITIONAL_INDIVIDUAL,
      path: "/conflicts/checks/:matterId/add-individual",
      props: true,
      component: () =>
        import("@/views/conflicts/check-add-individual/CheckAddIndividual.vue"),
      beforeEnter: (to, from, next) => {
        if (waffle.flag_is_active("conflict_checks")) {
          next();
        } else {
          next({ name: RouteNames.CONFLICTS.COMING_SOON });
        }
      },
      meta: {
        title: "Check - Add additional individual",
      },
    },
    {
      name: RouteNames.CONFLICTS.ADD_BUSINESS,
      path: "/conflicts/create/add-business/:id?",
      props: true,
      component: () =>
        import("@/views/conflicts/check-add-business/CheckAddBusiness.vue"),
      beforeEnter: (to, from, next) => {
        if (waffle.flag_is_active("conflict_checks")) {
          next();
        } else {
          next({ name: RouteNames.CONFLICTS.COMING_SOON });
        }
      },
      meta: {
        title: "Check Create - Add business",
      },
    },
    {
      name: RouteNames.CONFLICTS.ADD_ADDITIONAL_BUSINESS,
      path: "/conflicts/checks/:matterId/add-business",
      props: true,
      component: () =>
        import("@/views/conflicts/check-add-business/CheckAddBusiness.vue"),
      beforeEnter: (to, from, next) => {
        if (waffle.flag_is_active("conflict_checks")) {
          next();
        } else {
          next({ name: RouteNames.CONFLICTS.COMING_SOON });
        }
      },
      meta: {
        title: "Check - Add additional business",
      },
    },
    {
      name: RouteNames.CONFLICTS.COMING_SOON,
      path: "/conflicts/coming-soon",
      props: true,
      component: () =>
        import(
          "@/views/conflicts/conflicts-coming-soon/ConflictsComingSoon.vue"
        ),
      meta: {
        title: "",
      },
    },
    {
      name: RouteNames.SCREENING.DETAIL,
      path: "/screening/:id",
      props: true,
      component: () =>
        import("@/views/screening/screening-details/ScreeningDetails.vue"),
      beforeEnter: (to, from, next) => {
        if (waffle.flag_is_active("enable_screening")) {
          next();
        } else {
          // Go somewhere sensible (e.g. Engage list) if screening is disabled
          next({ name: RouteNames.ENGAGE.LIST });
        }
      },
      meta: {
        title: "Screening Results",
      },
    },
    {
      name: RouteNames.ENGAGE.LIST,
      path: "/engage/",
      component: () => import("@/views/engage/engage-list/EngageList.vue"),
      meta: {
        title: "Engage",
      },
      beforeEnter: (to, from, next) => {
        // support for legacy query params
        if (to.query.createmodal_show) {
          const queryParams = to.query;
          const newQueryParams = new URLSearchParams();
          Object.keys(queryParams).forEach((key) => {
            if (key.startsWith("createmodal_") && key !== "createmodal_show") {
              const newKey = snakeToCamelCase(key.replace("createmodal_", ""));
              if (
                queryParams[key] !== null &&
                typeof queryParams[key] === "string"
              ) {
                newQueryParams.set(newKey, queryParams[key] as string);
              }
            }
          });
          newQueryParams.set("externalSource", "true");
          drawers.show("engage-create", newQueryParams);
          next({
            ...to,
            query: {
              limit: to.query.limit || 10,
              assigned_reviewer__in:
                to.query.assigned_reviewer__in || userConfig.userId,
            },
            hash: `#/drawer/engage-create/?${newQueryParams.toString()}`,
          });
        } else if (
          !to.query.limit ||
          (!to.query.assigned_reviewer__in && userConfig.userId)
        ) {
          next({
            ...to,
            query: {
              ...to.query,
              limit: 10,
              assigned_reviewer__in: userConfig.userId,
            },
          });
        } else {
          next();
        }
      },
    },
    {
      name: RouteNames.BUSINESSES.LIST,
      path: "/businesses/",
      component: () =>
        import("@/views/businesses/businesses-list/BusinessesList.vue"),
      meta: {
        title: "Businesses List",
      },
      beforeEnter: (to, from, next) => {
        if (
          !to.query.limit ||
          (!to.query.created_by__in && userConfig.userId)
        ) {
          next({
            ...to,
            query: {
              ...to.query,
              limit: 10,
              created_by__in: userConfig.userId,
            },
          });
        } else {
          next();
        }
      },
    },
    {
      name: RouteNames.BUSINESSES.SEARCH,
      path: "/businesses/search",
      component: () =>
        import("@/views/businesses/business-search/BusinessSearch.vue"),
      meta: {
        title: "Business Search",
      },
    },
    {
      name: RouteNames.BUSINESSES.DETAIL,
      path: "/businesses/:id",
      props: (route) => ({
        id: route.params.id,
        activeTab: route.query.active_tab,
        initialVisit: route.query.initial_visit === "true",
      }),
      component: () =>
        import("@/views/businesses/business-detail/BusinessDetail.vue"),
      meta: {
        title: "Business Details",
      },
    },
    {
      name: RouteNames.BUSINESSES.SANCTIONS_REPORT,
      path: "/businesses/:businessId/sanctions_report/:id",
      props: (route) => ({
        id: route.params.id,
        companyReportId: route.query.company_report_id,
      }),
      component: () =>
        import(
          "@/views/businesses/business-sanctions-report/BusinessSanctionsReport.vue"
        ),
      meta: {
        title: "Sanctions Report",
      },
    },
    {
      name: RouteNames.BUSINESSES.SANCTIONS_DETAILS,
      path: "/businesses/:businessId/sanctions_report/:id/sanction/:sanctionId",
      props: (route) => ({
        sanctionId: route.params.sanctionId,
        searchHitId: route.query.search_hit_id,
        confirmedMatch: route.query.confirmed_match,
        applicationStatus: route.query.application_status,
      }),
      component: () =>
        import(
          "@/views/businesses/business-sanctions-report/BusinessSanctionReportResult.vue"
        ),
      meta: {
        title: "Sanction Result",
      },
    },
    {
      name: RouteNames.MONITORING.LIST,
      path: "/monitoring/",
      component: () => import("@/views/monitoring/MonitoringTable.vue"),
      meta: {
        title: "Monitoring",
      },
      beforeEnter: (to, from, next) => {
        if (!to.query.limit) {
          next({
            ...to,
            query: { ...to.query, limit: 10, status__in: "Ready for review" },
          });
        } else {
          next();
        }
      },
    },
    {
      name: RouteNames.COMPANY_REPORT.CREATE,
      path: "/company_report/",
      component: () =>
        import("@/views/company-report-experiment/CompanyReport.vue"),
      meta: {
        title: "Company Report",
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.hidden) {
    return false;
  }
  const suffix = " | Legl";
  document.title = to.meta.title ? to.meta.title + suffix : "Legl";
  if (!userConfig.hasLoaded && !to.meta.isAuth) {
    await bootstrapVueApp();
  }
  next();
});
